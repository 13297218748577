import React from 'react'
import {Link} from 'gatsby'

const Post = (props) => {
    return(
        <Link key={props.post.slug} to={`/blog${props.post.slug}`} className="Blogs__post">
            <div className="Blogs__post-container">
                <h3 className="Blogs__post-title">{props.post.title}</h3>
                <span className="Blogs__post-date">{props.post.date}</span>
            </div>
        </Link>
    )
}

export default Post