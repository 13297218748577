import { graphql, useStaticQuery } from 'gatsby'
import React, { useEffect, useState } from 'react'
import Layout from '../components/mylayout'
import Posts from '../components/posts'
import SEO from '../components/seo'

const Blog = ({location}) => {
    const [posts, setPosts] = useState([])

    const res = useStaticQuery(graphql`
        query MyQuery {
            allMarkdownRemark(sort: {order: DESC, fields: frontmatter___date}) {
                edges {
                    node {
                        frontmatter {
                            title
                            date(formatString: "MMMM Do, YYYY")
                        }
                        fields {
                            slug
                        }
                    }
                }
            }
        }
    `)

    let allPosts = res.allMarkdownRemark.edges.map(edge => {
        return(
            {
                slug: edge.node.fields.slug,
                title: edge.node.frontmatter.title,
                date: edge.node.frontmatter.date,
            }
        )
    })
    
    useEffect(() => {
        const newPosts = []
        for(let i=0; i<allPosts.length; i++){
            newPosts.push(allPosts[i])
        }
        setPosts(newPosts)
    }, [])

    console.log(posts)
    return(
        <Layout location={location}>
            <SEO title="Blog Posts" description="The archive of everything I've written"/>
            <div className="container Blogs">
                <div className="row">
                    <div className="col-sm-9 col-12 Blogs__wrapper">
                        <div className="Blogs__wrapper-content">
                            <h1 className="heading Blogs__title">Blog Posts</h1>
                            <p className="Blogs__subtitle">The archive of everything I've written.</p>
                            <Posts posts={posts}/>
                        </div>
                    </div>
                    <div className="col-sm-2 offset-1 d-none d-sm-block">
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Blog